import '../App.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import React from 'react';
import QRCode from 'qrcode';
import socketIO from "socket.io-client";
import icon from '../images/dogeIcon.png';
import { fetchDogeUsdRate, dogeToUsd, usdToDoge } from "../utils/price";

export class Main extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [{name: 'Brooklyn Slice', color: 'pink', price_cents: 332}], 
			page: 0, 
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleClickPay = this.handleClickPay.bind(this);
		this.handleClickDoge = this.handleClickDoge.bind(this);
		this.handleKeyClick = this.handleKeyClick.bind(this);
		this.handleContinueClick = this.handleContinueClick.bind(this);
		this.setQRCode = this.setQRCode.bind(this);
		this.checkPayment = this.checkPayment.bind(this);
	}

	// const [dogeUsdPrice, setDogeUsdPrice] = useState(null);

	// const reload = async () => {
	// 	setIsLoading(true);
	// 	try {
	// 	const rate = await fetchDogeUsdRate();
	// 	setDogeUsdPrice(rate);
	// 	} catch (err) {
	// 	console.error(err);
	// 	} finally {
	// 	await sleep(500);
	// 	setIsLoading(false);
	// 	}
	// };

	// useEffect(() => {
	// 	reload();
	// }, []);

	handleClick(e) {
		this.setState((state, props) => ({
		  items: [...state.items, {name: e.target.innerText, price_cents: parseInt(e.target.dataset.price)}]
		}));
	}

	handleClickPay() {
		this.setState({page: 1});
	}

	handleClickDoge() {
		this.setState({page: 2});
	}

	handleKeyClick(e) {
		
	}

	handleContinueClick() {
		this.setState({page: 3});
		let priceUrl = 'https://sochain.com//api/v2/get_price/DOGE/USD';
		fetch(priceUrl)
			.then(res => res.json())
			.then(payload => {
				let price = payload.data.prices[0].price
				let addr = 'DCQF28zA6TKeS9S9z7GPrTbrBB6gaseX5B';

				let walletUrl = process.env.REACT_APP_WALLET_URL;

				walletUrl = walletUrl ? walletUrl : 'doge.b3-well.com/test'
				fetch(`https://${walletUrl}`).then(resaddr => resaddr.text())
					.then(txtaddr => {
						console.log('text:')
						console.log(txtaddr);
						addr = txtaddr;

						let paymentUrl = `dogecoin:${addr.trim()}?amount=${this.total() / price}&message=DogeExpress`;
						console.log(paymentUrl)
						setTimeout(this.checkPayment, 500, addr)
						QRCode.toDataURL(paymentUrl, this.setQRCode);
				})
		  });
	}

	checkPayment(paymentAddr) {
		let checkUrl = `https://doge.b3-well.com/check/${paymentAddr}`
		fetch(checkUrl).then(res => res.text())
			.then(txt => {
				console.log('bal rcvd:')
				console.log(txt)
				if (parseInt(txt) > 0) {
					console.log('payment complete!')
					this.setState({rcvd: parseFloat(txt)})
				} else {
					console.log('payment pending...')
					setTimeout(this.checkPayment, 500, paymentAddr)
				}
			})
	}

	setQRCode(err, url) {
		this.setState({qrCodeUrl: url});
		// const socket = socketIO.connect("http://b3-well.com:4567");
		// socket.on("sendSockets", (sockets) => {
		// 	console.log(`sockets: ${sockets}`)
		//     });
	}

	getDogeUSDRate = async () => {
		const rate = await fetchDogeUsdRate();
		console.log("rate: ", parseFloat(rate));
		console.log("total: ", this.total().toFixed(2));

		const newRate = parseFloat(rate);
		const totalDogecoin = (this.total().toFixed(2)) / newRate;
		console.log("totalDogecoin: ", totalDogecoin);
		return newRate;
	}

	total() {
		return (this.state.items.map((item) => (item.price_cents))).reduce((partialSum, a) => partialSum + a, 0) / 100;
	}

	render() {
		if (this.state.page == 3) {
			return (
				<Container style={{backgroundColor: 'black', height: '800px', color: 'white'}}>
					<Row>
						<Col sm={{span: 5}}>
					 		<Row style={{backgroundColor: '#4189C7', height: '500px', margin: '10px', borderRadius: '4px'}}><Col>
								<Row style={{height: '60px'}} />
								{
									this.state.items.map((item, index) => (
										<Row key={`cart-${index}`}>
											<Col style={{textAlign: 'left'}} sm={{ span: 10 }}><strong>{item.name}</strong></Col>
											<Col style={{textAlign: 'right'}} span={2}>${(item.price_cents / 100).toFixed(2)}</Col>
										</Row>
									))
								}
								<Row>
									<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>- DogeExpress Discount</Col>
									<Col style={{textAlign: 'right'}} span={2}>- $0.33</Col>
								</Row>
							</Col></Row>

					 		<Row style={{backgroundColor: '#4189C7', height: '200px', margin: '10px', borderRadius: '4px'}}>
								<Col>

									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Subtotal:</Col>
										<Col style={{textAlign: 'right'}} span={1}>${this.total().toFixed(2)}</Col>
									</Row>
								
									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Tax:</Col>
										<Col style={{textAlign: 'right'}} span={1}>$0.33</Col>
									</Row>
								
									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Tip:</Col>
										<Col style={{textAlign: 'right'}} span={1}>$0.33</Col>
									</Row>
								
									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Balance due:</Col>
										<Col style={{textAlign: 'right'}} span={1}>${(this.total() + 0.33).toFixed(2)}</Col>
									</Row>

									<Row>
										<Col style={{textAlign: 'left'}} sm={{ span: 10 }}>Dogecoin due:</Col>
										<Col style={{textAlign: 'left'}} span={1}>${(this.total().toFixed(2)) / (this.getDogeUSDRate())}</Col>
									</Row>
								
								</Col>
							</Row>
						</Col>

						<Col sm={{span: 7}}>
								<Row style={{height: '120px'}} />

								<Row style={{fontSize: '30px', textAlign: 'center'}}>
									<Col><img src={icon} style={{width: '44px', height: '44px'}} /> DogeExpress payment</Col>
								</Row>

								<Row style={{height: '100px'}} />

								<Row style={{textAlign: 'center'}}>
									<Col>Please scan this code to complete payment</Col>
								</Row>

								<Row style={{height: '50px'}} />

								<Row style={{textAlign: 'center'}}>
									<Col>
										<img src={this.state.qrCodeUrl} />
									</Col>
								</Row>

								<Row style={{height: '50px'}} />

								{
									this.state.rcvd ? (
										<Row style={{textAlign: 'center'}}>
											<Col sm={{span: 2}} />
											<Col>Payment Received! D${this.state.rcvd}</Col>
											<Col sm={{span: 2}} />
											</Row>
										) : (
											<Row style={{textAlign: 'center'}}>
												<Col sm={{span: 2}} />
												<Col>Send only Dogecoin (DOGE) to this address. Sending any other coins may result in permanent loss.</Col>
												<Col sm={{span: 2}} />
											</Row>
										)
								}
						</Col>
					</Row>
				</Container>
			);
		}

		if (this.state.page == 2) {
			return (
				<Container style={{backgroundColor: 'black', height: '800px', color: 'white'}}>
					<Row style={{height: '120px'}} />

					<Row>
						<div style={{color: 'white', fontSize: '36px', textAlign: 'center'}}>Balance Due ${this.total()}</div>
						<div style={{color: 'white', fontSize: '30px', textAlign: 'center'}}>Would you like to leave a tip?</div>
					</Row>
					<Row style={{height: '56px'}} />

					<Row>
						<Col sm={{span: 1}}/>
						<Col sms={{span: 10}}>
							<Row style={{flexWrap: 'nowrap'}}>
								<Col style={{padding: '5px', margin: '0px', fontSize: '36px', textAlign: 'center', height: '100px', borderRadius: '4px'}} sm={{span: 4}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col style={{fontSize: '36px', textAlign: 'center'}}>25%</Col></Row>
										<Row><Col style={{fontSize: '21px', textAlign: 'center'}}>${(this.total() * 1.25).toFixed(2)}</Col></Row>
									</div>
								</Col>
								<Col style={{padding: '5px', margin: '0px', fontSize: '36px', textAlign: 'center', height: '100px', borderRadius: '4px'}} sm={{span: 4}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col style={{fontSize: '36px', textAlign: 'center'}}>20%</Col></Row>
										<Row><Col style={{fontSize: '21px', textAlign: 'center'}}>${(this.total() * 1.20).toFixed(2)}</Col></Row>
									</div>
								</Col>
								<Col style={{padding: '5px', margin: '0px', fontSize: '36px', textAlign: 'center', height: '100px', borderRadius: '4px'}} sm={{span: 4}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col style={{fontSize: '36px', textAlign: 'center'}}>18%</Col></Row>
										<Row><Col style={{fontSize: '21px', textAlign: 'center'}}>${(this.total() * 1.18).toFixed(2)}</Col></Row>
									</div>
								</Col>
							</Row>
						</Col>
						<Col sm={{span: 1}}/>
					</Row>

					<Row>
						<Col sm={{span: 1}} />
						<Col sms={{span: 10}}>
							<Row>
								<Col style={{padding: '5px', margin: '0px', fontSize: '21px', height: '100px', textAlign: 'center', lineHeight: '100px'}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col>Custom tip</Col></Row>
									</div>
								</Col>
								<Col style={{padding: '5px', margin: '0px', fontSize: '21px', height: '100px', textAlign: 'center', lineHeight: '100px'}}>
									<div style={{backgroundColor: 'gray'}}>
										<Row><Col>No tip</Col></Row>
									</div>
								</Col>
							</Row>
						</Col>
						<Col sm={{span: 1}} />
					</Row>

					<Row style={{height: '200px'}} />

					<Row style={{height: '50px', lineHeight: '50px'}}>
						<Col sm={{span: 1}} />
						<Col sm={{span: 10}} style={{textAlign: 'center', backgroundColor: '#169EDD', color: 'black'}} onClick={this.handleContinueClick} >Continue</Col>
						<Col sm={{span: 1}} />
					</Row>
				</Container>
			);
		}

		return (
		  <div className="App">
				<Container>

					<Row>
						<Col>
							<div style={{backgroundColor: 'black', height: '50px'}}>
							</div>
						</Col>
					</Row>

					<Row style={{columnGap: 0}}>
						<Col sm={{ span: 5 }} style={{paddingRight: 0}}>
							<div style={{height: '800px', backgroundColor: 'grey', padding: '15px'}}>

								<Row style={{backgroundColor: '#dfe0df'}}>
									<Col style={{textAlign: 'left'}} sm={{ span: 3 }}>Emp Id: 40</Col>
									<Col sm={{span: 6}} />
									<Col sm={{ span: 3 }}><strong>Jane Smith</strong></Col>
								</Row>

								<Row style={{backgroundColor: 'white', borderBottom: 'solid 1px'}}>
									<Col style={{color: '#149edc', textAlign: 'left'}} sm={{ span: 6 }}>Name</Col>
									<Col style={{color: '#149edc'}} span={2}>Qty</Col>
									<Col style={{color: '#149edc'}} span={2}>Each</Col>
									<Col style={{color: '#149edc'}} span={2}>Total</Col>
								</Row>

								<Row style={{backgroundColor: 'white', height: '400px', borderBottom: 'solid 1px'}}><Col>
								{
									this.state.items.map((item, index) => (
										<Row style={{backgroundColor: 'white'}} key={`cart-${index}`}>
											<Col style={{textAlign: 'left'}} sm={{ span: 6 }}>{item.name}</Col>
											<Col span={2}>1</Col>
											<Col span={2}>${(item.price_cents / 100).toFixed(2)}</Col>
											<Col span={2}>${(item.price_cents / 100).toFixed(2)}</Col>
										</Row>
									))
								}
								</Col></Row>

								<Row style={{backgroundColor: '#dfe0df', height: '300px'}}>
									<Col>
										<Row>
											<Col sm={{ span: 7}}>
												<Row>
													<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}} sm={{span: 3}}>Tab</Col>
													<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}} sm={{span: 3}}>+ Item</Col>
													<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}} sm={{span: 3}}>Split</Col>
												</Row>
											</Col>

											<Col sm={{ span: 1 }} />

											<Col style={{backgroundColor: '#a8a9a8', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px'}}>Cancel</Col>
											<Col style={{backgroundColor: '#149edc', margin: '5px', borderRadius: '4px', height: '50px', lineHeight: '50px', color: 'white'}}>Send</Col>

										</Row>

										<Row style={{height: '175px'}}>
											<Col sm={{ span: 6}}>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Credits:</strong></Col><Col>$0.00</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Discounts:</strong> </Col><Col>$0.00</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}>Tips (0%): </Col><Col>$0.00</Col>
												</Row>
											</Col>
											<Col sm={{ span: 6}}>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Subtotal:</strong> </Col><Col>${
														this.total().toFixed(2)
													}</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Tax:</strong> </Col><Col>$0.29</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}><strong>Balance Due:</strong> </Col><Col style={{color: 'red'}}>${
														(0.29 + this.total()).toFixed(2)
													}</Col>
												</Row>
												<Row>
													<Col style={{textAlign: 'left'}}>TOTAL: </Col><Col style={{color: '#149edc'}}>${
														(0.29 + this.total()).toFixed(2)
													}</Col>
												</Row>
											</Col>
										</Row>

										<Row>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Guests</Col>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Discnt</Col>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Dine In</Col>
											<Col style={{backgroundColor: '#149edc', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px', color: 'white'}}>Fast ($)</Col>
											<Col style={{backgroundColor: '#a8a9a8', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px'}}>Print</Col>
											<Col style={{backgroundColor: '#149edc', margin: '3px', height: '50px', lineHeight: '50px', borderRadius: '4px', color: 'white'}} onClick={this.handleClickPay}>Pay ($)</Col>
										</Row>

									</Col>
								</Row>

							</div>
						</Col>

						<Col sm={{ span: 7 }} style={{paddingLeft: 0}}>
							<div style={{height: '800px', backgroundColor: '#D1D1D0'}}>
								{this.rightPanel()}
							</div>
						</Col>
					</Row>

				</Container>
		  </div>
		);
	}

	rightPanel() {
		if (this.state.page == 0) {
			return (
				<>
					<Row style={{padding: '10px'}}>
						{
							[
								{name: 'Food ', color: '#169EDD' },
								{name: 'Soda Counter ', color: '#FF1732' },
								{name: 'Online Menu ', color: '#169EDD' },
								{name: 'Gift Cards', color: '#D1D1D0' },
							].map((item, index) => (
								<Col style={{
									fontSize: '14px',
									boxShadow: '0px 0px 4px #000000DB', 
									borderRadius: '4px', margin: '5px', 
									height: '75px', lineHeight: '75px', 
									backgroundColor: item.color, color: `${index == 3 ? 'black' : 'white'}`}} sm={{ span: 2 }}>{item.name}</Col>
							))
						}
					</Row>
	<hr/>
					<Row style={{padding: '10px'}}>
						{
							[
								{name: 'Pizza by the Slice', color: '#0053C4' },
								{name: 'Pizza Pies', color: '#C31A0F' },
								{name: 'Salads', color: '#029100' },
								{name: 'Heroes & Slides', color: '#AF3A00' },
							].map((item, index) => (
								<Col style={{
									fontSize: '12px',
									boxShadow: '0px 0px 4px #000000DB', 
									borderRadius: '4px', margin: '5px', 
									height: '75px', lineHeight: '75px', 
									backgroundColor: item.color, color: 'white'}} sm={{ span: 2 }}>{item.name}</Col>
							))
						}
					</Row>
	<hr/>
					<Row style={{padding: '10px'}}>
						{
							[
								{name: 'Pepperoni Slice', color: 		'#F78A9B', price_cents: 301},
								{name: 'Brooklyn Slice', color: 		'#F78A9B', price_cents: 332},
								{name: 'Margherita Slice', color: 	'#F78A9B', price_cents: 432},
								{name: 'Sophia Loren Slice', color: '#F78A9B', price_cents: 532},
								{name: 'Bianco Slice', color: 			'#F78A9B', price_cents: 442},

								{name: 'Grandma Slice', color: '#7fc6ff', price_cents: 632},
								{name: 'Cup & Char Slice', color: '#7fc6ff', price_cents: 232},
								{name: 'Tartufo Slice', color: '#7fc6ff', price_cents: 331},
								{name: 'Paesano Slice', color: '#7fc6ff', price_cents: 222},
								{name: 'Calabrese Slice', color: '#7fc6ff', price_cents: 443},
								{name: 'Apple Bacon Slice', color: '#7fc6ff', price_cents: 554},

								{name: 'Vegetarian Slice', color: '#FFFFFF', price_cents: 235},
								{name: 'Vegan Slice', color: '#0FE510', price_cents: 326},
								{name: 'Vegan Pepperoni', color: '#0FE510', price_cents: 427},
								{name: 'Garlic knots', color: '#FF7F00', price_cents: 548},
								{name: 'Chocolate chip', color: '#30009C', price_cents: 459},
								{name: 'Add Topping', color: '#FFFFFF', price_cents: 331},
								{name: 'TOO GOOD TO GO', color: '#FEE7E7', price_cents: 101},
							].map((content, index) => (
								<Col onClick={this.handleClick} key={`menu-${index}`} style={{
									border: '1px solid', borderRadius: '4px', margin: '5px', height: '75px', lineHeight: '75px', fontSize: '11px', backgroundColor: content.color
								}} sm={{span: 2}} data-price={content.price_cents}>{content.name}</Col>
							))
						}
					</Row>
				</>
			);
		} else if (this.state.page == 1) {
			return (
				<Row>
					<Col sm={{span: 2}} />

					<Col>
						<Row style={{height: '40px', lineHeight: '40px'}}>
							<Col style={{fontSize: '14px', paddingLeft: '0', textAlign: 'left'}} sm={{span: 3}}><strong>Balance due</strong></Col>
							<Col style={{fontSize: '14px', paddingRight: '0', textAlign: 'right'}} sm={{span: 9}}><strong>Amount tendered</strong></Col>
						</Row>

						<Row style={{paddings: '20px'}}>
							<Col sm={{span: 3}} style={{
								backgroundColor: '#a8a9a8', 
								borderRadius: '4px', 
								height: '50px', lineHeight: '50px', 
								border: '1px solid'}}>${this.total()}</Col>
							<Col sm={{span: 9}} style={{paddingRight: '0'}}><input style={{width: '100%'}} /></Col>
						</Row>

						<Row style={{height: '10px'}} />

						{
							[[1,2,3, '$26'],[4,5,6, '$30'],[7,8,9, '$40'],['C',0,'00', '<DEL']].map((row) => (
									<Row>
										{
											row.map((b, index) => (
												<Col style={
													{
														boxShadow: '0px 0px 4px #000000DB', 
														backgroundColor: `${index % 4 == 3 ? '#169EDD' : 'white'}`, 
														color: `${index % 4 == 3 ? 'white' : 'black'}`,
														margin: '2px', 
														borderRadius: '4px', 
														height: '132px', 
														width: '75px',
														lineHeight: '132px'}
												} onClick={this.handleKeyClick}>{b}</Col>
											))
										}
									</Row>
								)
							)
						}

					</Col>

					<Col sm={{span: 1}} />

					<Col sm={{span: 3}}>
						{
							[
								'Cash',
								'Credit',
								'Gift card',
								'DogeExpress',
								'Other',
							].map((method, index) => (
								<Row><Col style={{
									backgroundColor: '#a8a9a8', 
									borderRadius: '4px', 
									border: '1px solid', marginTop: '5px', marginLeft: '5px', height: '50px', lineHeight: '50px'}} sm={{span: 10}}
									onClick={this.handleClickDoge}
									>
										{ index == 3 ? <img src={icon} style={{width: '20px', height: '20px', marginRight: '5px'}} /> : null}
										{method}
									</Col></Row>
							))
						}
					</Col>
				</Row>
			);
		}
	}		
}

export default Main;
